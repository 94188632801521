"use client"

import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs"
import { useState } from "react"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select"
import TabItems from "./tab-items"

export function TabWrapper({ data }) {
  const [selectedFakultas, setSelectedFakultas] = useState("Informatika")

  // Akses data yang benar dari prop
  const kurikulumData = data || []

  console.log(kurikulumData) // Log untuk debugging

  return (
    <>
      <Tabs defaultValue="Informatika">
        {/* Select (Mobile View) */}
        <div className="lg:hidden block">
          <Select>
            <SelectTrigger className="w-[180px] float-end">
              <SelectValue placeholder="Informatika" />
            </SelectTrigger>
            <SelectContent>
              {kurikulumData.map((item, index) => (
                <SelectItem
                  key={index}
                  value={item.departement}
                  className="bg-transparent">
                  <TabsList className="bg-transparent">
                    <TabsTrigger
                      value={item.departement}
                      className="data-[state=active]:bg-transparent data-[state=active]:dark:text-white data-[state=active]:text-slate-900 capitalize data-[state=active]:shadow-none">
                      {item.departement}
                    </TabsTrigger>
                  </TabsList>
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>

        {/* Tabs (Desktop View) */}
        <TabsList className="dark:bg-slate-800 bg-background lg:grid w-full grid-cols-3 lg:grid-cols-6 hidden">
          {kurikulumData.map((item, index) => (
            <TabsTrigger
              key={index}
              value={item.departement}
              onClick={() => setSelectedFakultas(item.departement)}
              className="text-semibold  capitalize">
              {item.departement}
            </TabsTrigger>
          ))}
        </TabsList>

        {/* TabItems */}
        <TabItems kurikulumData={kurikulumData} />
      </Tabs>
    </>
  )
}
